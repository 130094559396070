import * as React from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ClientConfig } from "gen/clients/llts";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { SelectOption } from "@mui/base";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";

interface Props {
  clientConfigs: ClientConfig[];
  onSelection: (selectedClientConfig: ClientConfig) => void;
  onCancel: () => void;
  isLoading: boolean;
  error: unknown;
}

const CompanySelection: React.FC<Props> = ({ clientConfigs, onSelection, onCancel, isLoading, error }) => {
  const { t } = useTranslation();

  const companyOptions: SelectOption<string>[] = React.useMemo(
    () =>
      clientConfigs
        .map(c => ({ label: c.companyName, value: c.clientId }))
        .sort((option1, option2) => option1.label.localeCompare(option2.label)),
    [clientConfigs]
  );

  const onSubmit = React.useCallback(
    values => {
      const selectedClientId = values.company;
      const selectedClientConfig = clientConfigs.find(clientConfig => clientConfig.clientId === selectedClientId);
      if (!selectedClientConfig) {
        throw new Error(`Cannot find ClientConfig with id [${selectedClientId}]`);
      }
      onSelection(selectedClientConfig);
    },
    [clientConfigs, onSelection]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {error && <SnackbarApiError error={error} />}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Typography component="h1" variant="h6" sx={{ my: 3 }}>
          We found several organizations that match your email address. Please select your organization.
        </Typography>
      </Box>
      <Formik
        initialValues={{
          company: ""
        }}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        <Form>
          <SelectOneField
            name="company"
            label="Organization"
            options={companyOptions}
            required={true}
            variant="outlined"
            disabled={isLoading}
          />
          <Grid container={true} spacing={1} sx={{ mt: 5, mb: 2 }}>
            <Grid item={true} xs={6}>
              <Button fullWidth variant="text" color="secondary" onClick={onCancel}>
                {t("common.cancel")}
              </Button>
            </Grid>
            <Grid item={true} xs={6}>
              <LoadingButton isLoading={isLoading} fullWidth variant="contained">
                {t("common.continue")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Container>
  );
};

export { CompanySelection };
