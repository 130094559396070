import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Auth } from "@aws-amplify/auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDialogState } from "../../../../hooks/useDialogState/useDialogState";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { SnackbarAlert } from "../../../SnackbarAlert/SnackbarAlert";
import { useUserSession } from "../../../../hooks/useUserSession";
import { ImpersonateUserDialog } from "./ImpersonateUserDialog";
import { UserGroup } from "../../../../gen/clients/llts";
import { signIn } from "../../../../store/userSession/userSessionActions";
import { useUserSessionOverride } from "../../../../hooks/useUserSessionOverride";

interface Props {
  username: string;
}

const UserMenu: React.FC<Props> = ({ username }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isFederatedSsoAccount, roles } = useUserSession();
  const [userMenuAnchor, setUserMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [isChangePasswordOpen, openChangePassword, closeChangePassword] = useDialogState();
  const [isLogInAsOpen, openLogInAs, closeLogInAs] = useDialogState();
  const [isSuccessPasswordChangeOpen, openSuccessPasswordChange, closeSuccessPasswordChange] = useDialogState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [userSessionOverride, , clearUserSessionOverride] = useUserSessionOverride();

  React.useEffect(() => {
    if (!userSessionOverride?.username || isLogInAsOpen) {
      return;
    }
    dispatch(
      signIn(
        userSessionOverride.username,
        userSessionOverride.email || "",
        userSessionOverride.roles || [],
        undefined,
        undefined,
        undefined
      )
    );
  }, [dispatch, isLogInAsOpen, userSessionOverride]);

  const onUserMenuButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setUserMenuAnchor(event.currentTarget);
    },
    [setUserMenuAnchor]
  );

  const closeUserMenu = React.useCallback(() => setUserMenuAnchor(null), [setUserMenuAnchor]);

  const signOut = React.useCallback(() => {
    Auth.signOut();
  }, []);

  const isUserOverrideAllowed = React.useMemo(() => !!roles?.includes(UserGroup.ADMIN), [roles]);

  const onTurnOffLogInAsClick = React.useCallback(() => {
    clearUserSessionOverride();
    window.location.href = "/";
  }, [clearUserSessionOverride]);

  const onChangePasswordClick = React.useCallback(() => {
    openChangePassword();
    closeUserMenu();
    closeSuccessPasswordChange();
  }, [closeUserMenu, openChangePassword, closeSuccessPasswordChange]);

  const onImpersonateUserClick = React.useCallback(() => {
    openLogInAs();
    closeUserMenu();
  }, [closeUserMenu, openLogInAs]);

  const onChangePasswordSuccess = React.useCallback(() => {
    closeChangePassword();
    openSuccessPasswordChange();
  }, [closeChangePassword, openSuccessPasswordChange]);

  return (
    <>
      {username && (
        <>
          {isSmallScreen ? (
            <IconButton onClick={onUserMenuButtonClick}>
              <Avatar />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              disableElevation={true}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={onUserMenuButtonClick}
              sx={{ textTransform: "unset" }}
            >
              {`${username} ${userSessionOverride ? "(Impersonation Mode)" : ""}`}
            </Button>
          )}
          <Menu
            anchorEl={userMenuAnchor}
            open={!!userMenuAnchor}
            onClose={closeUserMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{ sx: { minWidth: 180 } }}
          >
            {!isFederatedSsoAccount && (
              <MenuItem onClick={onChangePasswordClick}>{t("appHeader.userMenu.changePassword.title")}</MenuItem>
            )}
            {!userSessionOverride && isUserOverrideAllowed && (
              <MenuItem onClick={onImpersonateUserClick}>{t("appHeader.userMenu.startImpersonateUser")}</MenuItem>
            )}
            {userSessionOverride && (
              <MenuItem onClick={onTurnOffLogInAsClick}>{t("appHeader.userMenu.endImpersonateUser")}</MenuItem>
            )}
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={signOut}>{t("appHeader.userMenu.signOut")}</MenuItem>
          </Menu>
        </>
      )}

      {isChangePasswordOpen && (
        <ChangePasswordDialog onClose={closeChangePassword} onSuccess={onChangePasswordSuccess} />
      )}

      {isLogInAsOpen && (
        <ImpersonateUserDialog
          onClose={closeLogInAs}
          onSuccess={() => {
            closeLogInAs();
            history.push("/");
          }}
        />
      )}

      {isSuccessPasswordChangeOpen && (
        <SnackbarAlert message={t("appHeader.userMenu.changePassword.title")} severity="success" />
      )}
    </>
  );
};

export default UserMenu;
