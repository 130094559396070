import * as React from "react";
import { Alert, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ClientConfigsService, CustomFieldDefinition, Language, UtilsService, WorkflowOption } from "gen/clients/llts";
import { SelectOption } from "@mui/base";
import Stack from "@mui/material/Stack";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";

interface Props {
  clientId?: string;
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  workflowOptions?: WorkflowOption[];
  isProjectNameVisible?: boolean;
  hideLanguages?: boolean;
}

const LANGUAGE_COMPARATOR = (l1: Language, l2: Language) => {
  if (l1.preferred && !l2.preferred) {
    return -1;
  }
  if (!l1.preferred && l2.preferred) {
    return 1;
  }
  return l1.name.localeCompare(l2.name);
};

const WORKFLOW_COMPARATOR = (w1: WorkflowOption, w2: WorkflowOption) => w1.label.localeCompare(w2.label);

export enum ProjectInfoFieldName {
  projectName = "projectName",
  sourceLanguage = "sourceLanguage",
  targetLanguages = "targetLanguages",
  notes = "notes",
  xtrfServiceId = "xtrfServiceId"
}

export interface ProjectInfoFormValues {
  [ProjectInfoFieldName.sourceLanguage]: SelectOption<string> | null;
  [ProjectInfoFieldName.targetLanguages]: SelectOption<string>[];
  [ProjectInfoFieldName.notes]: string;
  [ProjectInfoFieldName.projectName]: string | undefined;
  [ProjectInfoFieldName.xtrfServiceId]: string;
}

const ProjectInfoSection: React.FC<Props> = ({
  clientId,
  projectCustomFieldDefinitions,
  workflowOptions,
  isProjectNameVisible,
  hideLanguages
}) => {
  const { t } = useTranslation();
  const [sourceLanguagesDisplayAll, setSourceLanguagesDisplayAll] = React.useState(false);
  const [targetLanguagesDisplayAll, setTargetLanguagesDisplayAll] = React.useState(false);

  const [selectedSourceLanguage, setSelectedSourceLanguage] = React.useState<string | null>(null);
  const [selectedTargetLanguages, setSelectedTargetLanguages] = React.useState<string[]>([]);

  const {
    data: languages,
    isLoading: isLanguagesLoading,
    error: languagesError
  } = useQuery("listLanguages", {
    enabled: !hideLanguages,
    queryFn: UtilsService.listLanguages
  });
  const { data: availableLanguages, isLoading: isAvailableLanguagesLoading } = useQuery(
    ["getAvailableLanguages", clientId],
    {
      enabled: !!clientId && !hideLanguages,
      queryFn: () => ClientConfigsService.getAvailableLanguages({ clientId: clientId || "" })
    }
  );

  const selectableWorkflowOptions = React.useMemo(
    () =>
      workflowOptions
        ? workflowOptions.sort(WORKFLOW_COMPARATOR).map(w => ({ label: w.label, value: `${w.xtrfServiceId}` }))
        : [],
    [workflowOptions]
  );

  const sourceLanguageOptions = React.useMemo(() => {
    const langs =
      sourceLanguagesDisplayAll || !availableLanguages?.length
        ? languages || []
        : languages?.filter(l => availableLanguages.includes(+l.id)) || [];
    return langs.sort(LANGUAGE_COMPARATOR).map(l => ({ label: l.name, value: `${l.id}` })) || [];
  }, [languages, availableLanguages, sourceLanguagesDisplayAll]);
  const targetLanguageOptions = React.useMemo(() => {
    const langs =
      targetLanguagesDisplayAll || !availableLanguages?.length
        ? languages || []
        : languages?.filter(l => availableLanguages.includes(+l.id)) || [];
    return langs.sort(LANGUAGE_COMPARATOR).map(l => ({ label: l.name, value: `${l.id}` })) || [];
  }, [languages, availableLanguages, targetLanguagesDisplayAll]);

  const isSameLanguageSelected = React.useMemo(() => {
    return selectedSourceLanguage !== null && selectedTargetLanguages.includes(selectedSourceLanguage);
  }, [selectedTargetLanguages, selectedSourceLanguage]);

  return (
    <Grid container spacing={4}>
      {isProjectNameVisible && (
        <Grid item xs={12}>
          <TextInputField
            name={ProjectInfoFieldName.projectName}
            label={t("createProject.createProjectForm.projectName.label")}
            helperText={t("createProject.createProjectForm.projectName.helperText")}
            required={true}
          />
        </Grid>
      )}
      {!hideLanguages && (
        <>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <FormFieldObserver
                fieldName={ProjectInfoFieldName.sourceLanguage}
                onChange={(option: SelectOption<string> | null) => {
                  setSelectedSourceLanguage(option ? option.value : null);
                }}
              />
              <SelectOneAutocompleteField
                name={ProjectInfoFieldName.sourceLanguage}
                label={t("createProject.createProjectForm.sourceLanguage.label")}
                helperText={t("createProject.createProjectForm.sourceLanguage.helperText")}
                options={sourceLanguageOptions}
                isLoading={isLanguagesLoading || isAvailableLanguagesLoading}
                isError={!!languagesError}
                required={true}
              />
              {(availableLanguages?.length || 0) > 0 && (
                <FormControlLabel
                  label={
                    <span style={{ whiteSpace: "nowrap" }}>
                      {t("createProject.createProjectForm.sourceLanguage.moreOptions")}
                    </span>
                  }
                  control={
                    <Checkbox
                      checked={sourceLanguagesDisplayAll}
                      onChange={() => setSourceLanguagesDisplayAll(prevState => !prevState)}
                    />
                  }
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <FormFieldObserver
                fieldName={ProjectInfoFieldName.targetLanguages}
                onChange={(options: SelectOption<string>[]) =>
                  setSelectedTargetLanguages(options.map(option => option.value))
                }
              />
              <SelectManyAutocompleteField
                name={ProjectInfoFieldName.targetLanguages}
                label={t("createProject.createProjectForm.targetLanguage.label")}
                helperText={t("createProject.createProjectForm.targetLanguage.helperText")}
                options={targetLanguageOptions}
                isLoading={isLanguagesLoading || isAvailableLanguagesLoading}
                isError={!!languagesError}
                required={true}
              />
              {(availableLanguages?.length || 0) > 0 && (
                <FormControlLabel
                  label={
                    <span style={{ whiteSpace: "nowrap" }}>
                      {t("createProject.createProjectForm.sourceLanguage.moreOptions")}
                    </span>
                  }
                  control={
                    <Checkbox
                      checked={targetLanguagesDisplayAll}
                      onChange={() => setTargetLanguagesDisplayAll(prevState => !prevState)}
                    />
                  }
                />
              )}
            </Stack>
          </Grid>

          {isSameLanguageSelected && (
            <Grid item xs={12}>
              <Alert severity="warning">{t("createProject.createProjectForm.sameLanguageWarning")}</Alert>
            </Grid>
          )}

          {selectableWorkflowOptions && selectableWorkflowOptions.length > 0 && (
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <SelectOneField
                  name={ProjectInfoFieldName.xtrfServiceId}
                  label={t("createProject.createProjectForm.selectableWorkflow.label")}
                  helperText={t("createProject.createProjectForm.selectableWorkflow.helperText")}
                  options={selectableWorkflowOptions}
                  required={true}
                />
              </Stack>
            </Grid>
          )}
        </>
      )}
      {/* Project custom fields */}
      {projectCustomFieldDefinitions &&
        projectCustomFieldDefinitions.map(customFieldDefinition => (
          <React.Fragment key={customFieldDefinition.name}>
            {customFieldDefinition.visible !== false && (
              <Grid item xs={12}>
                <CustomField customFieldDefinition={customFieldDefinition} />
              </Grid>
            )}
          </React.Fragment>
        ))}
      {/* Notes */}
      <Grid item xs={12}>
        <TextInputField
          name={ProjectInfoFieldName.notes}
          label={t("createProject.createProjectForm.notes")}
          multiline={true}
          rows={5}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export { ProjectInfoSection };
