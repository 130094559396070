import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import { SelectOption } from "@mui/base";
import { useQuery } from "react-query";
import {
  JobRequest,
  JobRequestFile,
  JobRequestFileCategory,
  JobRequestsService,
  JobRequestStatus,
  MachineTranslationService
} from "gen/clients/llts";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { LabelValue } from "components/LabelValue/LabelValue";
import { SelectInput } from "components/SelectInput/SelectInput";
import { formatDate } from "utils/dateUtils";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { DownloadTranslatedFilesDialog } from "components/jobRequests/DownloadTranslatedFilesDialog/DownloadTranslatedFilesDialog";
import { RequestHumanTranslationPanel } from "./RequestHumanTranslationPanel/RequestHumanTranslationPanel";
import { JobRequestState } from "../components/JobRequestState";
import { JobRequestNameEditable } from "./components/JobRequestNameEditable";
import { JobRequestUtils } from "../../../../utils/jobRequestUtils";

interface Props {
  jobRequestId: string;
}

const MtJobRequestDetailsPage: React.FC<Props> = ({ jobRequestId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedTargetLanguageId, setSelectedTargetLanguageId] = React.useState<string>();
  const [isRequestHumanTranslationPanelOpen, openRequestHumanTranslationPanel, closeRequestHumanTranslationPanel] =
    useDialogState();
  const [isDownloadTranslatedFilesDialogOpen, openDownloadTranslatedFilesDialog, closeDownloadTranslatedFilesDialog] =
    useDialogState();

  const {
    data: jobRequest,
    isLoading,
    error,
    refetch
  } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId }),
    refetchInterval: (jr: JobRequest | undefined) => (JobRequestUtils.isInProgress(jr?.status) ? 10000 : false),
    cacheTime: 0
  });

  const { data: mtSettings } = useQuery(["getMtSettings"], {
    queryFn: MachineTranslationService.getMachineTranslationSettings
  });

  const targetLanguageOptions: SelectOption<string>[] = React.useMemo(
    () =>
      jobRequest?.targetLanguages
        ?.map(l => ({
          label: l.name,
          value: l.id as string
        }))
        .sort(SELECT_OPTION_COMPARATOR) || [],
    [jobRequest?.targetLanguages]
  );

  React.useEffect(() => {
    if (targetLanguageOptions && targetLanguageOptions.length) {
      setSelectedTargetLanguageId(targetLanguageOptions[0].value);
    }
  }, [targetLanguageOptions]);

  const sourceFiles = React.useMemo(
    () =>
      jobRequest?.files
        ?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT)
        ?.sort((f1, f2) =>
          f1.fileName
            .substring(0, f1.fileName.lastIndexOf("."))
            .localeCompare(f2.fileName.substring(0, f2.fileName.lastIndexOf(".")))
        ),
    [jobRequest]
  );

  const translatedFiles = React.useMemo(
    () =>
      selectedTargetLanguageId &&
      jobRequest?.files
        ?.filter(f => f.languageId === selectedTargetLanguageId)
        ?.sort((f1, f2) =>
          f1.fileName
            .substring(0, f1.fileName.lastIndexOf("_"))
            .localeCompare(f2.fileName.substring(0, f2.fileName.lastIndexOf("_")))
        ),
    [selectedTargetLanguageId, jobRequest]
  );

  const onReturnClick = React.useCallback(() => history.push("/machineTranslation/fileTranslation"), [history]);

  const onFileLinkClick = React.useCallback(
    async (file: JobRequestFile) => {
      const downloadUrlResponse = await JobRequestsService.getJobRequestFileDownloadUrl({
        jobRequestId,
        fileKey: encodeURIComponent(file.fileKey)
      });
      const downloadUrl = downloadUrlResponse.downloadUrl;
      if (downloadUrl) {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.click();
      }
    },
    [jobRequestId]
  );

  const onDownloadAllClick = React.useCallback(async () => {
    openDownloadTranslatedFilesDialog();
  }, [openDownloadTranslatedFilesDialog]);

  const onRequestHumanTranslationSuccess = React.useCallback(() => {
    refetch();
    closeRequestHumanTranslationPanel();
  }, [closeRequestHumanTranslationPanel, refetch]);

  if (error) {
    return <ErrorPage apiError={error} />;
  }
  if (isLoading || !jobRequest) {
    return <LoadingPage />;
  }
  return (
    <Stack mt={2}>
      <Stack direction="row" justifyItems="center" alignItems="center">
        <IconButton onClick={onReturnClick}>
          <ArrowBackIosIcon />
        </IconButton>
        <Box flexGrow={1}>
          <JobRequestNameEditable jobRequest={jobRequest} onChange={refetch} />
        </Box>
      </Stack>
      <Card sx={{ p: 2, mt: 1 }}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12} sm={6}>
            <Stack spacing={1}>
              <LabelValue
                label="Status"
                value={
                  <Stack direction="row" spacing={1}>
                    <JobRequestState status={jobRequest.status} />
                    {jobRequest.status === JobRequestStatus.SUBMITTED_TO_XTRF && (
                      <InfoIcon tooltip={t("machineTranslation.fileTranslation.requests.submittedToXtrfStatusInfo")} />
                    )}
                    {jobRequest.status === JobRequestStatus.CREATED && (
                      <InfoIcon tooltip={t("machineTranslation.fileTranslation.requests.createdStatusInfo")} />
                    )}
                    {jobRequest.status === JobRequestStatus.FAILED && (
                      <InfoIcon tooltip={t("machineTranslation.fileTranslation.requests.failedStatusInfo")} />
                    )}
                  </Stack>
                }
              />
              {jobRequest.xtrfInternalId && (
                <LabelValue
                  label={t("machineTranslation.fileTranslation.requests.humanTranslationProject")}
                  value={
                    <Link
                      href={`${process.env.REACT_APP_XTRF_HOME}/customers/main.html#!quote/${jobRequest.xtrfInternalId}`}
                      target="_blank"
                    >
                      <Typography>{jobRequest.xtrfName}</Typography>
                    </Link>
                  }
                />
              )}
              <LabelValue
                label={t("machineTranslation.fileTranslation.requests.submittedOn")}
                value={<Typography>{formatDate(jobRequest.createTime)}</Typography>}
              />
            </Stack>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Stack spacing={1}>
              <LabelValue
                label={t("machineTranslation.fileTranslation.requests.sourceLanguage")}
                value={<Typography>{jobRequest.sourceLanguage?.name}</Typography>}
              />
              <LabelValue
                label={t("machineTranslation.fileTranslation.requests.targetLanguages")}
                value={
                  <Typography>
                    {jobRequest.targetLanguages
                      ?.map(l => l.name)
                      .sort((l1, l2) => l1.localeCompare(l2))
                      .join(", ")}
                  </Typography>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Card>

      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} sm={6}>
          <Card sx={{ mt: 2, height: 400 }}>
            <CardHeader
              title={
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">{t("machineTranslation.fileTranslation.requests.sourceFiles")}</Typography>
                  <Box sx={{ height: 40, paddingTop: "5px" }}>
                    <Typography variant="body1">{jobRequest.sourceLanguage?.name}</Typography>
                  </Box>
                </Stack>
              }
            />
            <CardContent sx={{ overflowY: "auto", pt: 0 }}>
              <Stack spacing={0.5}>
                {sourceFiles?.map(f => (
                  <Typography key={f.fileName} variant="body2">
                    <Link href="#" onClick={() => onFileLinkClick(f)}>
                      {f.fileName}
                    </Link>
                  </Typography>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <Card sx={{ mt: 2, height: 400 }}>
            <CardHeader
              title={
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h6">
                      {t("machineTranslation.fileTranslation.requests.mtTranslatedFiles")}
                    </Typography>
                    {translatedFiles &&
                      translatedFiles.length > 0 &&
                      !JobRequestUtils.isInProgress(jobRequest.status) && (
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="outlined"
                          onClick={onDownloadAllClick}
                          size="small"
                        >
                          {t("machineTranslation.fileTranslation.requests.downloadAllBtn")}
                        </Button>
                      )}
                  </Stack>
                  <SelectInput
                    options={targetLanguageOptions}
                    onChange={setSelectedTargetLanguageId}
                    value={selectedTargetLanguageId}
                    size="small"
                    SelectProps={{
                      native: true
                    }}
                  />
                </Stack>
              }
            />
            <CardContent sx={{ overflowY: "auto", pt: 0 }}>
              <Stack spacing={0.5}>
                {(!translatedFiles || translatedFiles.length === 0) && (
                  <Typography variant="body2">{t("machineTranslation.fileTranslation.requests.noFiles")}</Typography>
                )}
                {translatedFiles &&
                  translatedFiles.map(f => (
                    <Typography key={f.fileName} variant="body2">
                      <Link href="#" onClick={() => onFileLinkClick(f)}>
                        {f.fileName}
                      </Link>
                    </Typography>
                  ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item={true} xs={12}>
          {!(jobRequest.status === JobRequestStatus.COMPLETED || jobRequest.status === JobRequestStatus.FAILED) && (
            <Box textAlign="center">
              <Button onClick={onReturnClick} variant="outlined">
                {t("machineTranslation.fileTranslation.actions.return")}
              </Button>
            </Box>
          )}
          {(jobRequest.status === JobRequestStatus.COMPLETED || jobRequest.status === JobRequestStatus.FAILED) &&
            !mtSettings?.disableHumanEscalation && (
              <Stack spacing={2}>
                <Alert severity="info">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("machineTranslation.fileTranslation.requests.humanTranslationInfo")
                    }}
                  />
                </Alert>
                <Box textAlign="center">
                  <Button onClick={onReturnClick} sx={{ mr: 1 }}>
                    {t("machineTranslation.fileTranslation.actions.return")}
                  </Button>
                  <Button variant="contained" onClick={openRequestHumanTranslationPanel}>
                    {t("machineTranslation.fileTranslation.actions.requestHumanTranslation")}
                  </Button>
                </Box>
              </Stack>
            )}
        </Grid>
      </Grid>

      {isRequestHumanTranslationPanelOpen && jobRequest.sourceLanguage?.id && jobRequest.targetLanguages && (
        <RequestHumanTranslationPanel
          jobRequestId={jobRequestId}
          requestName={jobRequest.name}
          sourceLanguageId={jobRequest.sourceLanguage.id as string}
          targetLanguageIds={jobRequest.targetLanguages.map(l => l.id as string)}
          files={jobRequest.files || []}
          onClose={closeRequestHumanTranslationPanel}
          onSuccess={onRequestHumanTranslationSuccess}
        />
      )}
      {isDownloadTranslatedFilesDialogOpen && (
        <DownloadTranslatedFilesDialog
          jobRequestId={jobRequestId}
          zipFileName={`mt-translation-${jobRequestId}.zip`}
          onClose={closeDownloadTranslatedFilesDialog}
          onSuccess={closeDownloadTranslatedFilesDialog}
        />
      )}
    </Stack>
  );
};

export { MtJobRequestDetailsPage };
